import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { onError } from '@apollo/client/link/error';
import { AuthService } from './services/auth/auth.service';
import { ToastNotificationService } from './services/toast-notification/toast-notification.service';
import { SiteService } from './services/site/site.service';
import { Site } from './commons/interface/site.interface';
// import { AuthService } from './services/auth/auth.service';

enum ERROR {
  ACCESS_RESTRICTION_ERROR = 'AccessRestrictionError',
  SITENOTEXISTS = 'SiteNotExists',
  MUTATION_RESTRICTION_ERROR = 'MutationRestrictionError',
}
const uri = environment.APP_SYNC_URL; // <-- add the URL of the GraphQL server here
export function getIdToken() {
  let msalKeys: any;
  let idToken: any;

  msalKeys = localStorage.getItem(
    `msal.token.keys.${environment.AZURE_CLIENT_ID}`,
  );
  msalKeys = JSON.parse(msalKeys);
  if (msalKeys) {
    const idTokenKey = msalKeys.idToken[0];
    idToken = localStorage.getItem(idTokenKey);
    idToken = JSON.parse(idToken);
    return idToken.secret;
  }
  return '';
}

@NgModule({
  exports: [ApolloModule],
})
export class GraphQLModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    private siteService: SiteService,
    private authService: AuthService,
    private toastService: ToastNotificationService,
  ) {
    const http = httpLink.create({
      uri,
    });
    const middleware = new ApolloLink((operation, forward) => {
      let token = getIdToken();
      operation.setContext({
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      });
      return forward(operation);
    });
    const logoutLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );

      if (networkError) {
        const error = JSON.parse(JSON.stringify(networkError)); //beacuse status key was not showing the the network error
        console.log(
          `[Network error]: ${error.status} ${JSON.stringify(error)}`,
        );
        if (
          error.status === 401 ||
          error.error.errors[0]['errorType'] === 'AuthorizerFailureException'
        ) {
          this.toastService.showToast(
            'danger',
            'Session Expired ',
            'Please login again...',
            undefined,
            undefined,
            this.toastService.toastTimeoutMedium,
          );
          setTimeout(() => {
            this.authService.logout();
          }, 1000);
        }
      }
    });

    const userRestriction = onError(
      ({ graphQLErrors, networkError, response, operation }) => {
        let graphqlResponse: any = response;
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        if (
          graphqlResponse?.errors[0]?.errorType ===
          ERROR.ACCESS_RESTRICTION_ERROR
        ) {
          this.toastService.showToast(
            'danger',
            'Access Restriction',
            'Not allowed to access',
            undefined,
            undefined,
            this.toastService.toastTimeoutMedium,
          );
          this.siteService.activeSite = {} as Site;
          setTimeout(() => {
            this.authService.redirect('main/site/home');
          }, 1000);
        } else if (
          graphqlResponse?.errors[0]?.errorType ===
          ERROR.MUTATION_RESTRICTION_ERROR
        ) {
          this.toastService.showToast(
            'danger',
            'User is not authorized to perform task.',
            'Not authorized',
            undefined,
            undefined,
            this.toastService.toastTimeoutMedium,
          );
        }
      },
    );

    apollo.create({
      link: middleware.concat(userRestriction.concat(logoutLink.concat(http))),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          errorPolicy: 'all',
          fetchPolicy: 'no-cache',
        },
        mutate: {
          errorPolicy: 'all',
          fetchPolicy: 'no-cache',
        },
        watchQuery: {
          errorPolicy: 'all',
          fetchPolicy: 'no-cache',
        },
      },
    });
  }
}
