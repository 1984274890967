<si-landing-page
  heading="MetaHub"
  subheading="Building X"
  [subtitle]="'APP.CLAIM' | translate"
  [availableLanguages]="availableLanguages"

>
  <button
    type="submit"
    class="btn btn-primary w-100"
    *ngIf="!loginDisplay"
    (click)="login()"
  >
    {{ "APP.LOGIN" | translate }}
  </button>
</si-landing-page>
