import { inject, Injectable } from '@angular/core';
import {
  Link,
  SiToastNotificationService,
  ToastStateName,
} from '@simpl/element-ng';
// import { LOG_EVENT } from '@simpl/live-preview';

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationService {
  // logEvent = inject(LOG_EVENT);

  /*
        showToast('success', 'Success!', 'Success message.', undefined, undefined, 2000)
        // showToast('danger', 'Danger!', 'Danger message.')
        // warning: showActionableToast('info', 'Request an action!', 'Please confirm to execute.', 'Confirm')

  */
  private toastNotificationService = inject(SiToastNotificationService);
  toastTimeoutLong = 8000;
  toastTimeoutMedium = 5000;
  toastTimeoutShort = 3000;
  constructor() {}

  showToast(
    state: ToastStateName,
    title: string,
    message: string,
    disableAutoClose?: boolean,
    disableManualClose?: boolean,
    timeout?: number,
    action?: Link,
  ) {
    // const toast =
    this.toastNotificationService.showToastNotification({
      state,
      title,
      message,
      disableAutoClose,
      disableManualClose,
      timeout,
      action,
    });
    // toast.hidden?.subscribe(() => this.logEvent('host hidden'));
  }

  showActionableToast(
    state: ToastStateName,
    title: string,
    message: string,
    linkTitle: string,
  ) {
    this.showToast(state, title, message, undefined, undefined, 5000, {
      title: linkTitle,
      // action: () => this.onAction()
    });
  }

  // onAction() {
  //   this.logEvent('onAction');
  // }

  hideAll() {
    this.toastNotificationService.hideToastNotification();
  }
}
