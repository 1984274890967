import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { Router } from '@angular/router';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isIframe = false;
  loginDisplay = false;
  role = '';
  msalKeys: any;
  accessToken: any;
  accessTokenKey: any;
  userInfoKey: any;
  userInfo: any;
  userProfile: any;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private http: HttpClient,
  ) {}

  ngOnInItCall() {
    this.role = this.getRole() as string;
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  ngOnDestroyCall() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay) {
      // getting the autorization token to get access of the exposed APIs
      if (this.role && this.role === 'Data Uploader') {
        this.router.navigate(['/main/site/new-site/local-hero']);
      } else if (this.role) {
        this.router.navigate(['/main/site/']);
      } else {
        this.router.navigate(['/main/select-role']);
      }
    }
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    }
    // localStorage.removeItem('role');
  }
  getRole(): string | null {
    return localStorage.getItem('role');
  }
  setRole(role: string) {
    this.role = role;
    localStorage.setItem('role', role);
  }

  getUserProfile() {
    this.msalKeys = localStorage.getItem(
      `msal.token.keys.${environment.AZURE_CLIENT_ID}`,
    );
    this.msalKeys = JSON.parse(this.msalKeys);
    if (this.msalKeys) {
      this.accessTokenKey = this.msalKeys.accessToken[0];
      this.accessToken = localStorage.getItem(this.accessTokenKey);
      this.accessToken = JSON.parse(this.accessToken);

      this.userInfoKey = `${this.accessToken.homeAccountId}-${this.accessToken.environment}-${this.accessToken.realm}`;
      this.userInfo = localStorage.getItem(this.userInfoKey);
      this.userProfile = JSON.parse(this.userInfo);
      this.refreshToken();
      return this.userProfile;
    }
  }
  refreshToken() {
    this.http
      .get('https://graph.microsoft.com/v1.0/me')
      .subscribe((val) => val);

    const account = this.authService.instance.getAllAccounts()[0];
    this.authService
      .acquireTokenSilent({
        scopes: [''],
        account,
        authority: `https://login.microsoftonline.com/${environment.AZURE_TENANT_ID}/`,
      })
      .subscribe(
        (val) => {
          // console.log(val);
        },
        (error) => {
          console.log(
            'error: unable to get the new access token and idToken beacause refresh token is expired!! => ',
            error,
          );
          this.logout();
        },
      );
    setInterval(
      () => {
        this.http
          .get('https://graph.microsoft.com/v1.0/me')
          .subscribe((val) => val);
        const account = this.authService.instance.getAllAccounts()[0];
        this.authService
          .acquireTokenSilent({
            scopes: [''],
            account,
            authority: `https://login.microsoftonline.com/${environment.AZURE_TENANT_ID}/`,
          })
          .subscribe(
            (val) => {
              // console.log(val);
            },
            (error) => {
              console.log(
                'error: unable to get the new access token and idToken beacause refresh token is expired!! => ',
                error,
              );
              this.logout();
            },
          );
      },
      40 * 60 * 1000,
    ); // 40 minutes
  }
  redirect(path: string) {
    this.router.navigate([path]);
  }
}
