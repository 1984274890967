import { gql } from 'apollo-angular';

export const GET_ALL_SITES_FOR_USER = () => gql`
  query MyQuery($key: String, $sortType: SortType, $starred: Boolean) {
    getAllSitesForUser(
      limit: 1000000
      sort: { key: $key, sortType: $sortType }
      starred: $starred
    ) {
      items {
        address
        campus
        country
        customerId
        iBase
        id
        isFavourite
        name
        recipeName
        vertical
        customerDetails
        enrichmentStatus
      }
    }
  }
`;

export const GET_SITE_TREE_VIEW = () => gql`
  query MyQuery {
    getAllSitesTreeStructure(limit: 1000000) {
      location {
        badge
        label
        state
        stateIndicatorColor
        children {
          badge
          label
          stateIndicatorColor
          state
        }
      }
      customer {
        badge
        label
        state
        stateIndicatorColor
        children {
          badge
          label
          stateIndicatorColor
          state
        }
      }
    }
  }
`;
