import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthService,
    private settingsService: SettingsService,
  ) {}
  loginDisplay = false;
  availableLanguages = this.settingsService.availableLanguages;
  ngOnInit(): void {
    this.authService.ngOnInItCall();
    this.loginDisplay = this.authService.loginDisplay;
  }

  setLoginDisplay() {
    this.authService.setLoginDisplay();
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.authService.ngOnDestroyCall();
  }
}
