import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Site } from 'src/app/commons/interface/site.interface';
// import { Site } from 'src/app/commons/interface/site.interface';
import { Apollo } from 'apollo-angular';
import { GET_ALL_SITES_FOR_USER } from 'src/app/graphql/home/query';
import { ToastNotificationService } from '../toast-notification/toast-notification.service';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private _activeSite: Site = {} as Site;
  private _namedGraphURI: any = '';

  activeSiteChange: Subject<Site> = new Subject<Site>();
  activeNamedGraphURIChange: Subject<any> = new Subject<any>();

  constructor(
    private apollo: Apollo,
    private toastService: ToastNotificationService,
  ) {
    this.activeSiteChange.subscribe((val: Site) => {
      this._activeSite = val;
    });
    this.activeNamedGraphURIChange.subscribe((val: Site) => {
      this._namedGraphURI = val;
    });
  }

  get activeSite() {
    return this._activeSite;
  }
  set activeSite(val) {
    this.activeSiteChange.next(val);
  }

  set activeSiteNamedGraphURI(val) {
    this.activeNamedGraphURIChange.next(val);
    // this.namedGraphURI = val;
  }

  get activeSiteNamedGraphURI() {
    return this._namedGraphURI;
  }

  getAllSitesForUser() {
    return this.apollo
      .query<any>({
        query: GET_ALL_SITES_FOR_USER(),
        variables: {
          key: 'customerId',
          sortType: 'ASCENDING',
          starred: false,
        },
        errorPolicy: 'all',
      })
      .subscribe(({ data, loading, errors }) => {
        this.toastService.hideAll();
        this.toastService.showToast(
          'success',
          'Success',
          'Site details.',
          undefined,
          undefined,
          this.toastService.toastTimeoutMedium,
        );
        return data.getAllSitesForUser.items;
      });
  }
}
